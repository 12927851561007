<script>
import { computed, reactive, ref } from "vue";
import { useParallax, useTitle } from "@vueuse/core";

export default {
  name: "Home",
  components: {},
  setup() {
    const targetSport = ref(null);
    const targetRest = ref(null);
    const parallaxSport = reactive(useParallax(targetSport));
    const parallaxRest = reactive(useParallax(targetRest));
    // eslint-disable-next-line no-unused-vars
    const title = useTitle(
      "Государственное автономное учреждение Республики Бурятия Дирекция спортивных сооружений"
    );

    const layerSportImage = computed(() => {
      return `transform: translateX(${parallaxSport.tilt * 150}px) translateY(${
        parallaxSport.roll * 40
      }px)`;
    });

    const layerSportDesign = computed(() => {
      return `transform: translateX(${parallaxSport.tilt * 60}px) translateY(${
        parallaxSport.roll * 100
      }px)`;
    });

    const layerSportCircle = computed(() => {
      return `transform: translateX(${parallaxSport.tilt * 40}px) translateY(${
        parallaxSport.roll * 40
      }px)`;
    });

    const layerRestImage = computed(() => {
      return `transform: translateX(${parallaxRest.tilt * 40}px) translateY(${
        parallaxRest.roll * 150
      }px)`;
    });

    const layerRestDesign = computed(() => {
      return `transform: translateX(${parallaxRest.tilt * 60}px) translateY(${
        parallaxRest.roll * 100
      }px)`;
    });

    const layerRestCircle = computed(() => {
      return `transform: translateX(${parallaxRest.tilt * 40}px) translateY(${
        parallaxRest.roll * 40
      }px)`;
    });

    const targetSportCircle = ref(null);

    const sportMouseLeave = () =>
      targetSportCircle.value.removeAttribute("style");

    const targetRestCircle = ref(null);

    const restMouseLeave = () =>
      targetRestCircle.value.removeAttribute("style");

    return {
      targetSport,
      layerSportImage,
      layerSportDesign,
      layerSportCircle,
      targetSportCircle,
      sportMouseLeave,
      targetRest,
      layerRestImage,
      layerRestDesign,
      layerRestCircle,
      targetRestCircle,
      restMouseLeave,
    };
  },
};
</script>
<template>
  <div class="home home-view">
    <div class="home__wrapper">
      <div
        ref="targetSport"
        class="home__category-card sport"
        @mouseleave="sportMouseLeave"
      >
        <div
          ref="targetSportCircle"
          class="circle circle-primary"
          :style="layerSportCircle"
        ></div>
        <img src="../assets/sport-image.png" alt="" :style="layerSportImage" />
        <img
          src="../assets/sport-design.png"
          alt=""
          :style="layerSportDesign"
        />
        <div class="circle circle-overlay"></div>
        <div class="menu-section">
          <div class="menu-title">
            <router-link
              :to="{
                name: 'Category',
                params: { slug: 'sport' },
              }"
              >СПОРТ</router-link
            >
          </div>
          <ul class="menu-list">
            <li class="menu-link--item">
              <router-link
                :to="{
                  name: 'Page',
                  params: { type: 'sport', slug: 235 },
                }"
                >бассейн</router-link
              >
            </li>
            <li class="menu-link--item">
              <router-link
                :to="{
                  name: 'Page',
                  params: { type: 'sport', slug: 257 },
                }"
                >лукодром</router-link
              >
            </li>
            <li class="menu-link--item">
              <router-link
                :to="{
                  name: 'Page',
                  params: { type: 'sport', slug: 246 },
                }"
                >футбольные поля</router-link
              >
            </li>
            <li class="menu-link--item">
              <router-link
                :to="{
                  name: 'Page',
                  params: { type: 'sport', slug: 294 },
                }"
                >ипподром</router-link
              >
            </li>
            <li class="menu-link--item">
              <router-link
                :to="{
                  name: 'Page',
                  params: { type: 'sport', slug: 292 },
                }"
                >спортзалы</router-link
              >
            </li>
            <li class="menu-link--item">
              <router-link
                :to="{
                  name: 'Page',
                  params: { type: 'sport', slug: 259 },
                }"
                >секции</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div ref="targetRest" class="home__category-card rest">
        <div
          ref="targetRestCircle"
          class="circle circle-secondary"
          :style="layerRestCircle"
          @mouseleave="restMouseLeave"
        ></div>
        <img src="../assets/rest-image.png" alt="" :style="layerRestImage" />
        <img src="../assets/rest-design.png" alt="" :style="layerRestDesign" />
        <div class="circle circle-overlay"></div>
        <div class="menu-section">
          <div class="menu-title">
            <router-link
              :to="{
                name: 'Category',
                params: { slug: 'vacation' },
              }"
              >ОТДЫХ</router-link
            >
          </div>
          <ul class="menu-list">
            <li class="menu-link--item">
              <router-link
                :to="{
                  name: 'Page',
                  params: { type: 'vacation', slug: 331 },
                }"
                >отели</router-link
              >
            </li>
            <li class="menu-link--item">
              <router-link
                :to="{
                  name: 'Page',
                  params: { type: 'vacation', slug: 327 },
                }"
                >спа</router-link
              >
            </li>
            <li class="menu-link--item">
              <router-link
                :to="{
                  name: 'Page',
                  params: { type: 'vacation', slug: 357 },
                }"
                >DSS_STUDIO</router-link
              >
            </li>
            <li class="menu-link--item">
              <router-link
                :to="{
                  name: 'Page',
                  params: { type: 'vacation', slug: 367 },
                }"
                >выставки</router-link
              >
            </li>
            <li class="menu-link--item">
              <router-link
                :to="{
                  name: 'Page',
                  params: { type: 'vacation', slug: 369 },
                }"
                >мероприятия</router-link
              >
            </li>
            <li class="menu-link--item">
              <router-link
                :to="{
                  name: 'Page',
                  params: { type: 'vacation', slug: 371 },
                }"
                >концерты</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass">
@import "../styles/scss/_mixins.scss"

.rest a:hover
  color: $secondary-color

.home
  &__wrapper
    display: grid
    grid-template-columns: 1fr 1fr
    min-height: calc(100vh - 206px)
    gap: 10px
    @media only screen and (max-width: $sizes-lg - $default-padding*2)
      min-height: calc(100vh - 247px)
    @media only screen and (max-width: $sizes-sm)
      min-height: calc(100vh - 116px)
      grid-template-columns: 1fr
  &__category-card
    position: relative
    width: 100%
    height: 100%
    overflow: hidden
    transition: all .3s ease-in
    &:hover
      background: #d4d4d4
    &.sport .circle-primary
      background: $primary-color !important
    &.rest .circle-secondary
      background: $secondary-color !important
    &:hover .circle-overlay
      transform: scale(1) translate(0, 0) !important
    &:hover.sport .menu-title a
      color: $primary-color !important
    &.sport .menu-title a
      color: #fff
    &.rest .menu-title a
      color: #fff
    &:hover.rest .menu-title a
      color: $secondary-color !important
    &:hover .menu-link--item
      margin-bottom: $default-padding*.8 !important
    &.sport
      text-align: end
      background: #47bdf0
    &.rest
      text-align: start
      background: #ff8a83
    & img
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      height: 100%
      width: 100%
      object-fit: contain
    & .circle
      width: $default-padding*42.5
      height: $default-padding*42.5
      border-radius: 50em
      transition: all .3s ease-in
      &.circle-primary
        position: absolute
        bottom: $default-padding*4
        left: -($default-padding*10)
        background: $primary-color-light
        &:not(:hover)
          transform: none !important
        @media only screen and (max-width: $sizes-md)
          bottom: 0
        & ~ .circle-overlay
          position: absolute
          bottom: -($default-padding*5.5)
          right: -($default-padding*5.5)
          background: rgba(255, 255, 255, 0.66)
          transform: scale(0) translate(100%, 100%)
          @media only screen and (max-width: $sizes-sm)
            bottom: -($default-padding*8.5)
            left: -($default-padding*14)
            // transform: scale(.6)
      &.circle-secondary
        position: absolute
        bottom: $default-padding*4
        right: -($default-padding*10)
        background: $secondary-color-light
        &:not(:hover)
          transform: none !important
        @media only screen and (max-width: $sizes-md)
          bottom: 0
        & ~ .circle-overlay
          position: absolute
          bottom: -($default-padding*5.5)
          left: -($default-padding*5.5)
          background: rgba(255, 255, 255, 0.66)
          transform: scale(0) translate(100%, 100%)
          @media only screen and (max-width: $sizes-sm)
            bottom: -($default-padding*8.5)
            left: -($default-padding*14)
            // transform: scale(.6)
    &.sport .menu-section
      position: absolute
      top: 53%
      right: $default-padding*15
      transform: translate(50%, -50%)
      @media only screen and (max-width: $sizes-md)
        top: 60%
        right: $default-padding*8.5
      @media only screen and (max-width: $sizes-sm)
        top: 50%
        right: $default-padding*6
      @media only screen and (max-width: $sizes-xs)
        top: 53%
        right: $default-padding*4.75
      & .menu-title
        font-size: $base-font-size*4
        font-weight: 800
        color: $base-color
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15)
        text-transform: uppercase
        letter-spacing: 0.05em
        line-height: $default-padding*4.8
        transition: all .3s ease-in
        @media only screen and (max-width: $sizes-md)
          font-size: $base-font-size*3
        @media only screen and (max-width: $sizes-sm)
          font-size: $base-font-size*2
        @media only screen and (max-width: $sizes-xs)
          font-size: $base-font-size*1.5
      & .menu-list
        list-style: none
        padding: 0
        min-height: $default-padding*14.2
        & .menu-link--item
          font-size: $base-font-size*.9
          font-weight: 500
          letter-spacing: 0.05em
          text-transform: uppercase
          line-height: $default-padding*1.7
          transition: all .4s ease-in-out
          @media only screen and (max-width: $sizes-xs + 45px)
            display: none
          @media only screen and (max-width: $sizes-xs)
            font-size: $base-font-size*.7
            line-height: $default-padding*1.5
    &.rest .menu-section
      position: absolute
      top: 53%
      left: $default-padding*15
      transform: translate(-50%, -50%)
      @media only screen and (max-width: $sizes-md)
        top: 60%
        left: $default-padding*8.5
      @media only screen and (max-width: $sizes-sm)
        top: 50%
        left: $default-padding*6
      @media only screen and (max-width: $sizes-xs)
        top: 48.5%
        left: $default-padding*5
      & .menu-title
        font-size: $base-font-size*4
        font-weight: 800
        color: $base-color
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15)
        text-transform: uppercase
        letter-spacing: 0.05em
        line-height: $default-padding*4.8
        transition: all .3s ease-in
        @media only screen and (max-width: $sizes-md)
          font-size: $base-font-size*3
        @media only screen and (max-width: $sizes-sm)
          font-size: $base-font-size*2
        @media only screen and (max-width: $sizes-xs)
          font-size: $base-font-size*1.5
      & .menu-list
        list-style: none
        padding: 0
        min-height: $default-padding*14.2
        & .menu-link--item
          font-size: $base-font-size*.9
          font-weight: 500
          letter-spacing: 0.05em
          text-transform: uppercase
          line-height: $default-padding*1.7
          transition: all .4s ease-in-out
          @media only screen and (max-width: $sizes-xs)
            font-size: $base-font-size*.7
            line-height: $default-padding*1.5
</style>
